import { useUpdateCompany } from '@hooks/auth/useUpdateCompany/useUpdateCompany';
import PageTitle2 from '@components/pageTitle2';
import { Controller, FormProvider } from 'react-hook-form';
import MyInputV2 from '@components/inputV2';
import { HCMId, maxLength } from '@utils/configs/Common/CommonConfigs';
import Label from '@components/label';
import CompanyField from '@components/CompanyField';
import SelectBirthDate from '@components/SelectBirthDate';
import CityButton from '@components/AdvanceSearch/components/Address/CityButton';
import styles from '@styles/components/UpdatePremiumAccount/UpdatePremiumAccount.module.scss';
import MyTextAreaInput from '@components/TextAreaInput';
import MyButton from '@components/Button';
import React, { useCallback } from 'react';
import UserConfiguarations from '@utils/configs/UserConfiguarations/userConfiguarations.json';
import moment from 'moment/moment';
import UploadMediaAndAttachment from '@components/modals/UpgradeCompany/components/UploadMediaAndAttachment';
import { useMutation } from '@apollo/client';
import { UPDATE_COMPANY_PROFILE } from '@schemas/mutations/users';
import pickBy from 'lodash/pickBy';
import { useUpdateCompanyContext } from '@components/modals/UpgradeCompany/context';

const formatDate = 'YYYY-MM-DD',
  currentFormat = 'YYYYMMDD';

export default function EnterInfo() {
  const { form, setStep, step } = useUpdateCompany();

  const { setOpenModal, setOpenModalSuccess } = useUpdateCompanyContext();

  const { control, setValue, handleSubmit, watch } = form;

  const [updateCompany, { loading }] = useMutation(UPDATE_COMPANY_PROFILE, {
    onCompleted: () => {
      setOpenModalSuccess(true);
      setOpenModal(false);
    },
  });

  const userTypeSelections = UserConfiguarations.userType;

  const establishedDate = moment(
    watch('establishedDate'),
    currentFormat,
  ).format(formatDate);

  const onNext = useCallback(
    async (data) => {
      if (step === 1) {
        setStep(2);
      }
      if (step === 2) {
        const t = {
          ...data,
          companyName: data.companyName,
          establishedDate: moment(establishedDate).isValid()
            ? establishedDate
            : null,
          companyLicense: data.companyLicense.map((item) => item.fileId),
          mediaOfCompany: data.mediaOfCompany.map((item) => item.fileId),
          attachments: data.attachments.map((item) => item.fileId),
        };
        try {
          await updateCompany({
            variables: { ...pickBy(t, (e) => e) },
          });
        } catch (e) {
          alert(e.message);
        }
      }
    },
    [step],
  );

  return (
    <div className="flex-1 flex flex-col overflow-y-auto relative">
      <PageTitle2
        onRowClick={() => {
          if (step === 2) {
            setStep(1);
          } else {
            setOpenModal(false);
          }
        }}
        title="Hoàn thành hồ sơ"
        wrapperClass="lg:hidden"
      />
      <div
        className="hidden lg:flex bg-white sticky top-0 border-b border-blue-main z-10"
        style={{ padding: '10px 19px' }}>
        <p className="uppercase text-16 font-textBold font-bold text-blue-main">
          hoàn thành hồ sơ cá nhân
        </p>
      </div>
      <FormProvider {...form}>
        {step === 1 && (
          <div
            className="flex flex-1 flex-col"
            style={{ padding: '12px 12px 32px' }}>
            <p className="heading4">Thông tin nhà phát triển</p>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <MyInputV2
                  inputClass="h-40px"
                  name="developerName"
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Nhà phát triển"
                  placeholder="Tên nhà phát triển"
                  labelContainerClass="w-134px"
                  isRequire
                  value={value}
                  disabled
                  showClose={false}
                />
              )}
              name="developerName"
            />
            <div className="w-full h-1 border-t border-gray-300 my-16px" />
            <p className="heading4">Kiểm tra lại thông tin của bạn</p>
            <div className="flex w-full items-center mt-8px">
              <img
                className="w-24px h-24px mr-4px"
                src="/svg/ico_info.svg"
                alt="info"
              />
              <p className="text-13 font-text text-blue-main">
                Vui lòng kiểm tra lại thông tin của bạn một lần nữa trước khi
                nâng cấp
              </p>
            </div>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <MyInputV2
                  inputClass="h-40px"
                  name="type"
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Vai trò"
                  placeholder="Vai trò"
                  labelContainerClass="w-134px"
                  isRequire
                  value={userTypeSelections.find((e) => e.key === value)?.value}
                  disabled
                  showClose={false}
                />
              )}
              name="type"
            />
            <Controller
              rules={{
                required: 'Vui lòng nhập mã số thuế',
              }}
              control={control}
              render={({ field: { value } }) => (
                <MyInputV2
                  inputClass="h-40px"
                  name="taxId"
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Mã số thuế"
                  placeholder="Nhập giá trị"
                  labelContainerClass="w-134px"
                  isRequire
                  value={value}
                  disabled
                  showClose={false}
                />
              )}
              name="taxId"
            />
            <Controller
              control={control}
              render={({
                field,
                field: { value, onChange },
                formState: { errors },
                fieldState: { invalid },
              }) => (
                <MyInputV2
                  {...field}
                  invalid={invalid}
                  inputClass="h-40px"
                  name="companyName"
                  errors={errors}
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Tên doanh nghiệp"
                  placeholder="Nhập giá trị"
                  labelContainerClass="w-134px"
                  isRequire
                  value={value}
                  onChange={(e) =>
                    onChange(e.target.value.substr(0, maxLength.name))
                  }
                  showMaxLength
                  maxLength={maxLength.name}
                  autoComplete="new-password"
                />
              )}
              name="companyName"
            />
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <MyInputV2
                  inputClass="h-40px"
                  name="phones"
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Số điện thoại"
                  placeholder="Nhập giá trị"
                  labelContainerClass="w-134px"
                  isRequire
                  value={value?.[0]}
                  disabled
                  showClose={false}
                />
              )}
              name="phones"
            />
            <Controller
              control={control}
              render={({
                field,
                field: { value, onChange },
                formState: { errors },
                fieldState: { invalid },
              }) => (
                <MyInputV2
                  {...field}
                  invalid={invalid}
                  errors={errors}
                  inputClass="h-40px"
                  name="email"
                  inputOuterClassName="flex-1"
                  inputClassName="flex flex-col lg:flex-row lg:items-center mt-16px"
                  label="Email"
                  placeholder="Nhập giá trị"
                  labelContainerClass="w-134px"
                  isRequire
                  value={value || ''}
                  onChange={(e) =>
                    onChange(e.target.value.substr(0, maxLength.email))
                  }
                />
              )}
              name="email"
            />
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <div className="flex flex-col lg:flex-row mt-16px">
                  <Label
                    label="Lĩnh vực kinh doanh"
                    isRequire
                    wrapperClass="w-133px"
                  />
                  <CompanyField value={value} onChange={() => {}} disabled />
                </div>
              )}
              name="companyFields"
            />
            <div className="w-full flex mt-16px flex-col lg:flex-row lg:items-center">
              <div style={{ minWidth: 133 }}>
                <Label label="Ngày thành lập" isRequire />
              </div>
              <SelectBirthDate name="establishedDate" />
            </div>
            <div className="w-full flex mt-16px flex-col lg:flex-row lg:items-center">
              <div style={{ minWidth: 133 }}>
                <Label label="Khu vực BĐS" isRequire />
              </div>
              <CityButton
                onDelete={() => setValue('province', HCMId)}
                selectAll={false}
                wrapperContent={styles.cityButton}
              />
            </div>
            <div className="w-full h-1 border-t border-gray-300 my-16px" />
            <Controller
              control={control}
              render={({
                fieldState: { invalid, error },
                field: { value, onChange },
              }) => {
                return (
                  <div className="flex flex-col mt-16px">
                    <Label label=" Giới thiệu về doanh nghiệp" isRequire />
                    <MyTextAreaInput
                      onDelete={() => onChange('')}
                      name="description"
                      errorMessage={error?.message}
                      invalid={invalid}
                      rows={5}
                      value={value || ''}
                      onChange={(e) => onChange(e.target.value.substr(0, 3168))}
                      maxlength={3168}
                      placeholder="Nhập giới thiệu về doanh nghiệp"
                    />
                  </div>
                );
              }}
              name="description"
            />
          </div>
        )}
        {step === 2 && <UploadMediaAndAttachment />}
      </FormProvider>
      <div className="flex w-full justify-end sticky bottom-0 bg-white border-t border-gray-300 p-8px">
        <MyButton
          label="Trở về"
          type="secondary"
          wrapperClass="w-120px mr-8px lg:flex hidden"
          onPress={() => {
            if (step === 2) {
              setStep(1);
            }
            if (step === 1) {
              setOpenModal(false);
            }
          }}
        />
        <MyButton
          disabled={loading}
          label={step === 1 ? 'Tiếp tục' : 'Hoàn thành'}
          wrapperClass="lg:w-120px w-full"
          onPress={handleSubmit(onNext)}
        />
      </div>
    </div>
  );
}
