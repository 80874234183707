import React, { useEffect, useState } from 'react';
import Modal, { type ModalProps } from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Slide from '@material-ui/core/Slide';
import styles from '@styles/components/ModalWrapper.module.scss';
import dynamic from 'next/dynamic';
const CloseIcon = dynamic(() => import('@public/svg/xIcon.svg'));
type Props = { modalClass?: string, hideClose?: boolean } & ModalProps;

export default function ModalWrapper(props: Props) {
  const {
    open,
    children,
    onClose,
    className,
    style,
    modalClass,
    isZIndex1500,
    hideClose = false,
  } = props;
  const [renderChildren, setRenderChildren] = useState(false);
  useEffect(() => {
    if (open && !renderChildren) {
      setRenderChildren(true);
    }
  }, [open]);
  if (!process.browser) {
    return null;
  }
  return (
    <>
      <Modal
        disableEnforceFocus={true}
        style={isZIndex1500 ? { zIndex: 1500 } : null}
        disableAutoFocus={true}
        className={`${styles.modal} ${modalClass}`}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        container={document.getElementById('modal-root')}>
        <Slide direction="up" in={open}>
          <div style={style} className={`${className} ${styles.modalInner}`}>
            {!hideClose && (
              <CloseIcon
                onClick={onClose}
                width={16}
                height={16}
                className={styles.closeButton}
              />
            )}
            {renderChildren && children}
          </div>
        </Slide>
      </Modal>
    </>
  );
}
