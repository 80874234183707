import styles from '@styles/components/UploadAttachments.module.css';

type Props = {
  data?: Array,
  onDelete?: Function,
  hideDelete?: boolean,
};

export default function AttachmentsTable({
  data = [],
  onDelete,
  hideDelete,
}: Props) {
  if (!data?.length) {
    return null;
  }
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full">
        <div className={styles.indexCol}>
          <p className="boldBodyText">STT</p>
        </div>
        <div className={styles.fileName}>
          <p className="boldBodyText">Tên tài liệu</p>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        {data?.map((item, index) => {
          return (
            <div className={styles.row} key={index}>
              <div className={styles.indexCol}>
                <p className="boldBodyText">{index + 1}</p>
              </div>
              <div className={`${styles.fileName} flex justify-between`}>
                <p
                  className="text-14 font-bold font-textBold underline truncate mr-24px"
                  style={{ color: '#106FFF' }}>
                  {item.name}
                </p>
                <div className="flex items-center space-x-2">
                  {item?.loading && (
                    <p
                      className="text-14 font-text underline truncate"
                      style={{ color: '#888888' }}>
                      {item?.status}
                    </p>
                  )}
                  {!hideDelete && (
                    <button onClick={() => onDelete(item.fileId)}>
                      <p
                        className="text-14 font-text underline truncate"
                        style={{ color: '#106FFF' }}>
                        Xóa
                      </p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
