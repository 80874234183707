import styles from '@styles/components/UploadAttachments.module.css';
import React from 'react';
import { useUploadImage } from '@utils/hooks/useUploadImage';
import { useDropzone } from 'react-dropzone';
import AttachmentsTable from '@components/UploadAttachments/AttachmentsTable';
import ErrorForFormHook from '@components/ErrorForFormHook';
import { useTableData } from '@utils/helper';

type Props = {
  description?: string,
  errorMessage?: string,
  allowVideo?: boolean,
  attachments?: Array,
  errors?: Array,
  setAttachments?: Function,
  maxFiles?: number,
  name?: string,
};

export default function UploadAttachments(props: Props) {
  const {
    description,
    attachments = [],
    setAttachments = () => {},
    maxFiles = 20,
    name,
    errors,
  } = props;

  const { doUploadImage, removeFile } = useUploadImage({
    maxFiles,
    mediaUrls: attachments,
    // setOpenModal,
    setMediaUrls: setAttachments,
    fieldName: name,
    maxImage: maxFiles,
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/pdf',
      'application/xhtml+xml',
      'application/xml',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/octet-stream',
      // ...(allowVideo ? ['video/*'] : []),
    ],
    multiple: true,
    onDrop: doUploadImage,
    disabled: attachments?.length >= maxFiles,
  });

  const tableRow = useTableData(attachments);

  return (
    <div className={styles.uploadImgContainer}>
      <p className="text-14 font-text text-blue-main text-center">
        {description}
      </p>
      <div
        {...getRootProps({
          className: `dropzone ${styles.uploadButton}`,
        })}>
        <input {...getInputProps()} style={{ display: 'none' }} />
        <img
          src="/svg/plusBlue.svg"
          className="w-16px h-16px mr-8px"
          alt="icon"
        />
        <p className="text-14 font-text text-blue-main">
          Chọn tài liệu tải lên
        </p>
      </div>
      <ErrorForFormHook name={name} errors={errors} wrapperClass="mt-8px" />
      <AttachmentsTable data={tableRow} onDelete={removeFile} />
    </div>
  );
}
