import React, { useContext } from 'react';

export const UpdateCompanyContext = React.createContext({
  openModal: false,
  setOpenModal: () => {},
  openModalSuccess: false,
  setOpenModalSuccess: () => {},
});

export const useUpdateCompanyContext = () => useContext(UpdateCompanyContext);
