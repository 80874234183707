import { useForm } from 'react-hook-form';
import { upgradeCompanySchema } from '@utils/validation/auth';
import moment from 'moment/moment';
import { getErrorsFormHook, getMedias } from '@utils/helper';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_USER_BASIC_INFO,
  MY_PROFILE_REQUEST,
} from '@schemas/queries/users';
import { companyFieldEnumType } from '@utils/configs/UserConfiguarations/userConfigs';
import { HCMId } from '@utils/configs/Common/CommonConfigs';

const blankState = {
  fullName: '',
  province: '',
  establishedDate: '',
  idNumber: '',
  company: '',
  companyName: '',
  email: '',
  avatar: '',
  description: '',
  companyLicense: [],
  mediaOfCompany: [],
  attachments: [],
};

const convertAttachmentFiles = async (listMedia) => {
  if (!listMedia?.length) {
    return [];
  }
  const data = await getMedias(listMedia);

  return data?.map((item) => {
    const [, name] = item?.originalKey.split('/');
    return { fileId: item?.fileId, name };
  });
};

const formatDate = 'YYYY-MM-DD',
  currentFormat = 'YYYYMMDD';

const formatDate1 = 'YYYYMMDD';

const formatRawData = (remoteValue) => {
  const establishedDate = remoteValue?.establishedDate
    ? moment(remoteValue?.establishedDate, 'YYYY-MM-DD').format(formatDate1)
    : moment();

  const otherCompany = remoteValue?.companyFields?.filter(
    (e) => !companyFieldEnumType.map((item) => item.key).includes(e),
  );

  return {
    ...remoteValue,
    province: remoteValue?.province?.id || HCMId,
    description: remoteValue?.description || '',
    establishedDate,
    isCompany: true,
    avatar: remoteValue?.avatar || '',
    avatarUrl: remoteValue?.avatar?.originalUrl,
    otherCompany: otherCompany?.[0] || '',
  };
};

export const useUpdateCompany = () => {
  const [step, setStep] = useState(1);
  const form = useForm({
    defaultValues: {
      ...blankState,
    },
    resolver: async (data) => {
      const { error, value: values } = upgradeCompanySchema.validate(
        {
          ...data,
          establishedDate: moment(data.establishedDate, currentFormat).format(
            formatDate,
          ),
          step,
        },
        {
          abortEarly: false,
        },
      );
      return getErrorsFormHook({ error, values });
    },
  });

  const { data, loading } = useQuery(MY_PROFILE_REQUEST, {
    variables: {
      tab: 'admin',
    },
  });

  const { data: dataBasic } = useQuery(GET_USER_BASIC_INFO, {
    variables: {
      userId: data?.myProfileRequest?.developer,
    },
    skip: !data?.myProfileRequest?.developer,
  });

  const { reset } = form;
  const convertData = formatRawData(data?.myProfileRequest);

  useEffect(async () => {
    if (data) {
      let attachments = [];
      await convertAttachmentFiles(data?.myProfileRequest?.attachments).then(
        (e) => (attachments = e),
      );
      reset({
        ...convertData,
        developerName: dataBasic?.getUserBasicInfo.fullName || '',
        companyLicense:
          data?.myProfileRequest?.companyLicense?.map((e) => ({
            fileId: e,
          })) || [],
        mediaOfCompany:
          data?.myProfileRequest?.mediaOfCompany?.map((e) => ({
            fileId: e,
          })) || [],
        attachments,
      });
    }
  }, [data, dataBasic]);

  return { form, loading, setStep, step };
};
