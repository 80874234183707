import Label from '@components/label';
import UploadImage from '@components/UpdatePremiumAccount/Components/UploadImage';
import { Controller, useFormContext } from 'react-hook-form';
import ChangeAvatar from '@components/ChangeAvatar';
import ErrorForFormHook from '@components/ErrorForFormHook';
import { useCallback } from 'react';
import UploadAttachments from '@components/UploadAttachments/UploadAttachments';

export default function UploadMediaAndAttachment() {
  const { control, setValue } = useFormContext();
  const updateAvatar = useCallback(async ({ fileId }) => {
    setValue('avatar', fileId);
  }, []);
  return (
    <div
      className="flex flex-col overflow-auto flex-1"
      style={{ padding: '12px 12px 24px' }}>
      <Controller
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <div className="flex w-full flex-col mt-24px">
              <Label
                wrapperClass="mb-4px"
                label="Hình ảnh và giấy phép kinh doanh của doanh nghiệp"
                isRequire
              />
              <UploadImage
                errors={errors}
                description={
                  'Bấm vào nút “Chọn ảnh tải lên" để đăng tải hình ảnh giấy phép kinh doanh của doanh nghiệp\nLưu ý: Mỗi hình ảnh không quá 1MB, tối đa 6 ảnh'
                }
                name="companyLicense"
                mediaUrls={value}
                setMediaUrls={onChange}
                maxFiles={6}
              />
            </div>
          );
        }}
        name="companyLicense"
      />
      <Controller
        control={control}
        render={({ field: { value }, formState: { errors } }) => {
          return (
            <div className="flex flex-col w-full border-t border-gray-300 mt-32px pt-24px">
              <div className="flex w-full flex-col lg:flex-row items-center lg:items-start">
                <Label label="Logo doanh nghiệp" isRequire />
                <ChangeAvatar avatarId={value} setAvatar={updateAvatar} />
              </div>
              <p className="heading4 mt-16px text-center lg:text-left">
                Nên chọn Logo doanh nghiệp thật để nhận các ưu đãi và bảo vệ tài
                khoản
              </p>
              <ErrorForFormHook name="avatar" errors={errors} />
            </div>
          );
        }}
        name="avatar"
      />
      <Controller
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <div className="flex w-full flex-col mt-24px">
              <Label
                wrapperClass="mb-4px"
                label="Hình ảnh về doanh nghiệp và nhân sự phụ trách"
                isRequire
              />
              <UploadImage
                errors={errors}
                description={
                  'Bấm vào nút “Chọn ảnh tải lên" để đăng tải hình ảnh về doanh nghiệp và nhân sự phụ trách'
                }
                name="mediaOfCompany"
                mediaUrls={value}
                setMediaUrls={onChange}
                maxFiles={6}
              />
            </div>
          );
        }}
        name="mediaOfCompany"
      />
      <Controller
        control={control}
        render={({ field: { value, onChange }, formState: { errors } }) => {
          return (
            <div className="flex w-full flex-col mt-24px">
              <Label
                wrapperClass="mb-4px"
                label="Tài liệu đính kèm"
                isRequire
              />
              <UploadAttachments
                errors={errors}
                description={
                  'Bấm vào nút “Chọn ảnh tải lên" để đăng tải tài liệu kinh doanh của doanh nghiệp'
                }
                name="attachments"
                attachments={value}
                setAttachments={onChange}
                maxFiles={6}
              />
            </div>
          );
        }}
        name="attachments"
      />
    </div>
  );
}
